define("discourse/plugins/discourse-topic-trade-buttons/discourse/templates/connectors/topic-above-post-stream/trade-buttons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.canTopicBeMarkedAsSold}}
    <DButton 
      @class="btn btn-primary"
      @label="topic_trading.sold"
      @action={{action "clickSoldButton"}}
      @actionParam={{this.model}}
    />
  {{/if}}
  {{#if this.model.canTopicBeMarkedAsPurchased}}
    <DButton
      @class="btn btn-primary"
      @label="topic_trading.purchased"
      @action={{action "clickPurchasedButton"}}
      @actionParam={{this.model}}
    />
  {{/if}}
  {{#if this.model.canTopicBeMarkedAsExchanged}}
    <DButton
      @class="btn btn-primary"
      @label="topic_trading.exchanged"
      @action={{action "clickExchangedButton"}}
      @actionParam={{this.model}}
    />
  {{/if}}
  {{#if this.model.canTopicBeMarkedAsCancelled}}
    <DButton
      @class="btn btn-default"
      @label="topic_trading.cancelled"
      @action={{action "clickCancelledButton"}}
      @actionParam={{this.model}}
    />
  {{/if}}
  
  */
  {
    "id": "pqJLtdrN",
    "block": "[[[41,[30,0,[\"model\",\"canTopicBeMarkedAsSold\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@class\",\"@label\",\"@action\",\"@actionParam\"],[\"btn btn-primary\",\"topic_trading.sold\",[28,[37,2],[[30,0],\"clickSoldButton\"],null],[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"model\",\"canTopicBeMarkedAsPurchased\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@class\",\"@label\",\"@action\",\"@actionParam\"],[\"btn btn-primary\",\"topic_trading.purchased\",[28,[37,2],[[30,0],\"clickPurchasedButton\"],null],[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"model\",\"canTopicBeMarkedAsExchanged\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@class\",\"@label\",\"@action\",\"@actionParam\"],[\"btn btn-primary\",\"topic_trading.exchanged\",[28,[37,2],[[30,0],\"clickExchangedButton\"],null],[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"model\",\"canTopicBeMarkedAsCancelled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@class\",\"@label\",\"@action\",\"@actionParam\"],[\"btn btn-default\",\"topic_trading.cancelled\",[28,[37,2],[[30,0],\"clickCancelledButton\"],null],[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-topic-trade-buttons/discourse/templates/connectors/topic-above-post-stream/trade-buttons.hbs",
    "isStrictMode": false
  });
});